import { NotFoundErrorSection } from '@y2/error-handlers';
import { ErrorLayout } from '../components/error-layout/error-layout';

const NotFound = () => (
  <ErrorLayout>
    <NotFoundErrorSection />
  </ErrorLayout>
);

export default NotFound;
